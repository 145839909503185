// src/utils/tipoMedicion.js
const tipoMedicion = {
    1: { nombre: 'Pulldown', url: 'Lanzamiento' },
    // 2: { nombre: 'Bullpen', url: 'Lanzamiento' },
    // 3: { nombre: 'Competencia', url: 'Lanzamiento' },
    4: { nombre: 'Peso', url: 'Corporal' },
    5: { nombre: 'Altura', url: 'Corporal' },
    //6: { nombre: 'Bat Speed Blast', url: 'Bateo' },
    7: { nombre: 'Exit Velo Tee', url: 'Bateo' },
    // 8: { nombre: 'Distancia Rapsodo', url: 'Bateo' },
    //9: { nombre: 'Peak Hand', url: 'Bateo' },
    //10: { nombre: 'Time to Contact', url: 'Bateo' },
    11: { nombre: 'Peso Muerto', url: 'Gimnasio' },
    12: { nombre: 'Press de Banco', url: 'Gimnasio' },
    13: { nombre: 'Sentadilla', url: 'Gimnasio' },
    16: { nombre: '90 pies', url: 'Velocidad' },
    17: { nombre: '60 yardas', url: 'Velocidad' },
    18: { nombre: 'Exit Velo Soft', url: 'Bateo' },
    19: { nombre: 'Exit Velo BP/Front', url: 'Bateo' },
  };
  
  export default tipoMedicion;
