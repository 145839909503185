import React, { useEffect, useState } from 'react';
import { Container, Navbar, Nav, Image, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import banderas from '../utils/banderas';

const Layout = ({ children }) => {
  const [user, setUser] = useState(null);
  const [jugador, setJugador] = useState(null);
  const [menuVisible, setMenuVisible] = useState(true); // Estado para mostrar/ocultar el menú
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.get('/api/auth/logout');
      localStorage.removeItem('jwt');
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('jwt');
      if (!token) {
        navigate('/login');
        return;
      }

      const authHeader = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
        setUser(response.data);
        if (response.data?.Jugador) {
          setJugador(response.data.Jugador);
        }
      } catch (error) {
        console.error('Error al obtener el perfil del usuario:', error);
        navigate('/login');
      }
    };
    fetchUser();
  }, [navigate]);

  if (!user) {
    return <p>Cargando información del usuario...</p>;
  }

  return (
    <div className="d-flex">
      {/* Botón para ocultar/mostrar el menú */}
      <Button
        variant="primary"
        onClick={() => setMenuVisible(!menuVisible)}
        className="position-fixed m-2"
        style={{ zIndex: 1000 }}
      >
        {menuVisible ? 'Ocultar Menú' : 'Mostrar Menú'}
      </Button>

      {/* Panel Lateral */}
      {menuVisible && (
        <div className="sidebar bg-primary text-white p-3" style={{ width: '250px', minHeight: '100vh' }}>
          <div className="user-profile text-center mb-4">
            <Image
              src={jugador?.foto || '/default-profile.jpg'}
              roundedCircle
              fluid
              className="mb-2"
              style={{ width: '100px', height: '100px' }}
            />
            <h5>{jugador ? `${jugador.nombre} ${jugador.apellido}` : 'Nombre del Jugador'}</h5>
            <p>#{jugador?.casaca || 'N/A'}</p>
            {jugador?.nacionalidad && (
              <img
                src={banderas[jugador.nacionalidad]?.url}
                alt="Bandera"
                style={{ width: '30px', height: '20px' }}
              />
            )}
            <p>{user.role || 'Sin Rol'}</p>
          </div>

          <Nav className="flex-column">
            {/* Acceso directo al perfil */}
            <Nav.Link as={Link} to="/profile" className="text-white">
              Mi Perfil
            </Nav.Link>

            {/* Sección Deportiva */}
            <div className="mt-3">
              <h6>Deportivo</h6>
              <Nav.Link as={Link} to="/bullpen" className="text-white">
                Bullpen
              </Nav.Link>
              <Nav.Link as={Link} to="/blast" className="text-white">
                Mediciones Blast
              </Nav.Link>
              <Nav.Link as={Link} to="/mediciones" className="text-white">
                Mediciones
              </Nav.Link>
              <Nav.Link as={Link} to="/entrenamiento" className="text-white">
                Mi Entrenamiento
              </Nav.Link>
            </div>

            {/* Sección Coach */}
            {['coach', 'admin'].includes(user.role) && (
              <div className="mt-3">
                <h6>Coach</h6>
                <Nav.Link as={Link} to="/admin/torneos" className="text-white">
                  Torneos
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/partidos" className="text-white">
                  Partidos
                </Nav.Link>
                <Nav.Link as={Link} to="/equiposvelez" className="text-white">
                  Equipos Vélez
                </Nav.Link>
                <Nav.Link as={Link} to="/entrenamiento/admin" className="text-white">
                  Administración de Entrenamientos
                </Nav.Link>
                <Nav.Link as={Link} to="/active-roster" className="text-white">
                  Jugadores Activos
                </Nav.Link>
              </div>
            )}

            {/* Sección Operaciones */}
            {user.role === 'admin' && (
              <div className="mt-3">
                <h6>Operaciones</h6>
                <Nav.Link as={Link} to="/admin/aranceles" className="text-white">
                  Carga de Aranceles
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/control-aranceles" className="text-white">
                  Control de Aranceles
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/presentes" className="text-white">
                  Carga de Presentismo
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/control-presentismo" className="text-white">
                  Control de Presentismo
                </Nav.Link>
                <Nav.Link as={Link} to="/admin/players" className="text-white">
                  Administración de Jugadores
                </Nav.Link>
              </div>
            )}

            {/* Cerrar Sesión */}
            <Nav.Link onClick={handleLogout} className="text-white mt-3">
              Cerrar Sesión
            </Nav.Link>
          </Nav>
        </div>
      )}

      {/* Contenido Principal */}
      <div className="content flex-grow-1">
        <Navbar bg="light" expand="lg" className="mb-4">
          <Container fluid>
            <Navbar.Brand as={Link} to="/">
              Inicio
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/active-roster">Roster Activo</Nav.Link>
              {/* Menú adicional solo para admin */}
              {user.role === 'admin' && (
                <Nav.Link as={Link} to="/admin/players">
                  Administrar Jugadores
                </Nav.Link>
              )}
            </Nav>
          </Container>
        </Navbar>

        <Container fluid>{children}</Container>
      </div>
    </div>
  );
};

export default Layout;
