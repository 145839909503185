import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Button, Modal, Form } from 'react-bootstrap';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, getDay } from 'date-fns'; // Importar funciones correctamente
import es from 'date-fns/locale/es'; // Importa el locale español
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';

//const localizer = momentLocalizer(moment);

// Configurar el localizador con date-fns
const locales = {
  'es': es,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => '1', // Lunes como primer día
  getDay,
  locales,
});

// Mensajes en español
const messages = {
  today: 'Hoy',
  previous: 'Anterior',
  next: 'Siguiente',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento',
  noEventsInRange: 'No hay eventos en este rango.',
  showMore: (total) => `+ Ver más (${total})`,
};

 //const Entrenamiento = ({ jugadorId: jugadorIdURL }) => {
const Entrenamiento = () => {
  const { jugadorId: jugadorIdURL } = useParams();
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCrearOpen, setModalCrearOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [jugadorId, setJugadorId] = useState(null);
  const [jugadorNombre, setJugadorNombre] = useState('');
  const [templateDetails, setTemplateDetails] = useState(null);
  const [isCoachOrAdmin, setIsCoachOrAdmin] = useState(false);
  const [nuevoEvento, setNuevoEvento] = useState({
    Fecha: '',
    TemplateEjercicioID: '',
    EjercicioID: '',
  });
  const [templates, setTemplates] = useState([]);
  const [ejercicios, setEjercicios] = useState([]);

  // Obtener usuario logueado o usar ID específico
  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('jwt');
      const authHeader = { headers: { Authorization: `Bearer ${token}` } };
      console.log('jugadorIdURL',jugadorIdURL);
      try {
        if (!jugadorIdURL) {
          const response = await axios.get('/api/auth/profile', authHeader);          
          setJugadorId(response.data.Jugador.id);
          setIsCoachOrAdmin(response.data.role !== 'Jugador');
          setJugadorNombre(`${response.data.Jugador.nombre} ${response.data.Jugador.apellido}`);
        } else {
          setJugadorId(jugadorIdURL);
          setIsCoachOrAdmin(true);
          const jugadorResponse = await axios.get(`/api/jugadores/${jugadorIdURL}`, authHeader);
          setJugadorNombre(`${jugadorResponse.data.nombre} ${jugadorResponse.data.apellido}`);
        }
      } catch (error) {
        console.error('Error al obtener datos del usuario:', error);
      }
    };

    fetchUser();
  }, [jugadorIdURL]);

  // Obtener eventos y datos de templates/ejercicios
  useEffect(() => {
    const fetchData = async () => {
      if (!jugadorId) return;

      const token = localStorage.getItem('jwt');
      const authHeader = { headers: { Authorization: `Bearer ${token}` } };
      console.log('jugadorId',jugadorId);
      try {
        const response = await axios.get(`/api/calendarioEntrenamiento/jugador/${jugadorId}`, authHeader);
        console.log('response.data',response.data);
        const formattedEvents = response.data.map((detalle) => ({
          id: detalle.ID,
          title: detalle.TemplateEjercicio
    ? `🗂️ ${detalle.TemplateEjercicio.nombre}`
    : `🏋️ ${detalle.Ejercicio.nombre}`,
          start: new Date(detalle.Fecha),
          end: new Date(detalle.Fecha),
          details: detalle.TemplateEjercicio || detalle.Ejercicio,
          tipo: detalle.TemplateEjercicio ? 'Template' : 'Ejercicio',       
          allDay: true,   
        }));
        setEvents(formattedEvents);

        const [templatesRes, ejerciciosRes] = await Promise.all([
          axios.get('/api/entrenamiento/templates', authHeader),
          axios.get('/api/entrenamiento/ejercicios', authHeader),
        ]);
        setTemplates(templatesRes.data);
        setEjercicios(ejerciciosRes.data);
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    };

    fetchData();
  }, [jugadorId]);

  // Crear nuevo evento
  const handleCrearEvento = async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = { headers: { Authorization: `Bearer ${token}` } };

    try {
      await axios.post('/api/calendarioEntrenamiento/crear', { ...nuevoEvento, JugadorID: jugadorId }, authHeader);
      setModalCrearOpen(false);
      setNuevoEvento({ Fecha: '', TemplateEjercicioID: '', EjercicioID: '' });
      // Refrescar eventos
      const response = await axios.get(`/api/calendarioEntrenamiento/jugador/${jugadorId}`, authHeader);
      const formattedEvents = response.data.map((detalle) => ({
        id: detalle.ID,
        title: detalle.TemplateEjercicio
          ? detalle.TemplateEjercicio.nombre
          : detalle.Ejercicio.nombre,
        start: new Date(detalle.Fecha),
        end: new Date(detalle.Fecha),
        details: detalle.TemplateEjercicio || detalle.Ejercicio,
        tipo: detalle.TemplateEjercicio ? 'Template' : 'Ejercicio',
        allDay: true,
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error al crear evento:', error);
    }
  };

  // Eliminar evento
  const handleEliminarEvento = async (id) => {
    const token = localStorage.getItem('jwt');
    const authHeader = { headers: { Authorization: `Bearer ${token}` } };

    try {
      await axios.delete(`/api/calendarioEntrenamiento/eliminar/${id}`, authHeader);
      setEvents(events.filter((event) => event.id !== id));
      setModalOpen(false);
    } catch (error) {
      console.error('Error al eliminar evento:', error);
    }
  };

  // Obtener detalles del template al seleccionarlo
  const fetchTemplateDetails = async (templateId) => {
    const token = localStorage.getItem('jwt');
    const authHeader = { headers: { Authorization: `Bearer ${token}` } };

    try {
      const response = await axios.get(`/api/entrenamiento/template/${templateId}`, authHeader);
      setTemplateDetails(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error('Error al obtener detalles del template:', error);
    }
  };

  const getEventStyle = (event) => {
    let backgroundColor = '';
    let borderColor = '';
  
    // Asignar colores según el tipo
    if (event.tipo === 'Template') {
      if (event.details.tipo === 'Fisico') {
        backgroundColor = 'red';
        borderColor = 'darkred';
      } else if (event.details.tipo === 'Bateo') {
        backgroundColor = 'green';
        borderColor = 'darkgreen';
      } else if (event.details.tipo === 'Pitcheo') {
        backgroundColor = 'blue';
        borderColor = 'darkblue';
      } else {
        backgroundColor = 'gray';
        borderColor = 'darkgray';
      }
    } else if (event.tipo === 'Ejercicio') {
      backgroundColor = 'lightgray';
      borderColor = 'black';
    }
  
    return {
      style: {
        backgroundColor,
        color: 'white',
        border: `1px solid ${borderColor}`,
        borderRadius: '4px',
        padding: '4px',
        fontWeight: 'bold',
      },
    };
  };
  

  return (
    <Container>
      <h1>Planificación de Entrenamiento de {jugadorNombre}</h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        formats={{
          timeGutterFormat: () => '', // Oculta la hora en la columna izquierda
          timeGutterHeader: () => '', // Oculta el encabezado del horario
          timeGutter: () => '',
        }}
        step={1440}
        timeslots={1}
        messages={messages}
        style={{ height: 600 }}
        views={['month', 'week', 'day']}        
        selectable={isCoachOrAdmin}
        eventPropGetter={(event) => getEventStyle(event)}
        onSelectEvent={(event) => {
          setSelectedEvent(event);
          if (event.tipo === 'Template') {
            fetchTemplateDetails(event.details.ID); // Obtener detalles del template
          } else {
            setModalOpen(true); // Abrir modal para ejercicios individuales
          }
        }}
        onSelectSlot={({ start }) => {
          if (isCoachOrAdmin) {
            setNuevoEvento({ ...nuevoEvento, Fecha: start });
            setModalCrearOpen(true);
          }
        }}
      />

      {/* Modal para ver detalles o eliminar un evento */}
      <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <>
              <p>
                <strong>Tipo:</strong> {selectedEvent.tipo}
              </p>
              <p>
                <strong>Título:</strong> {selectedEvent.title}
              </p>
              {selectedEvent.details?.descripcion && <p>{selectedEvent.details.descripcion}</p>}
              {selectedEvent.details?.link && (
                <a href={selectedEvent.details.link} target="_blank" rel="noopener noreferrer">
                  Ver Video
                </a>
              )}
              {isCoachOrAdmin && (
                <Button variant="danger" onClick={() => handleEliminarEvento(selectedEvent.id)}>
                  Eliminar Evento
                </Button>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalOpen(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para detalles del template */}
      <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {templateDetails ? (
            <>
              <h5>{templateDetails.nombre}</h5>
              <p>
                <strong>Tipo:</strong> {templateDetails.tipo}
              </p>
              <h6>Ejercicios:</h6>
              <ul>
                {templateDetails.TemplateEjercicioDetalles.map((detalle) => (
                  <li key={detalle.ID}>
                    <strong>{detalle.Ejercicio.nombre}</strong> - {detalle.Ejercicio.descripcion}
                    {detalle.Ejercicio.link && (
                      <>
                        {' '}
                        <a
                          href={detalle.Ejercicio.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Ver Video
                        </a>
                      </>
                    )}
                    <p>
                      Series: {detalle.series}, Repeticiones: {detalle.repeticiones}, Peso
                      sugerido: {detalle.peso_sugerido}
                    </p>
                  </li>
                ))}
                {isCoachOrAdmin && (
                <Button variant="danger" onClick={() => handleEliminarEvento(selectedEvent.id)}>
                  Eliminar Evento
                </Button>
              )}
              </ul>
            </>
          ) : (
            <p>Cargando detalles...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalOpen(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para crear un nuevo evento */}
      <Modal show={modalCrearOpen} onHide={() => setModalCrearOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Nuevo Evento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Fecha</Form.Label>
              <Form.Control
                type="date"
                value={moment(nuevoEvento.Fecha).format('YYYY-MM-DD')}
                onChange={(e) =>
                  setNuevoEvento({ ...nuevoEvento, Fecha: new Date(e.target.value) })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Template o Ejercicio</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) =>
                  setNuevoEvento({ ...nuevoEvento, TemplateEjercicioID: e.target.value, EjercicioID: '' })
                }
              >
                <option value="">Seleccionar Template</option>
                {templates.map((template) => (
                  <option key={template.ID} value={template.ID}>
                    {template.nombre}
                  </option>
                ))}
              </Form.Control>
              <Form.Control
                as="select"
                className="mt-2"
                onChange={(e) =>
                  setNuevoEvento({ ...nuevoEvento, EjercicioID: e.target.value, TemplateEjercicioID: '' })
                }
              >
                <option value="">Seleccionar Ejercicio</option>
                {ejercicios.map((ejercicio) => (
                  <option key={ejercicio.ID} value={ejercicio.ID}>
                    {ejercicio.nombre}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalCrearOpen(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleCrearEvento}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Entrenamiento;
