// src/pages/AdminEntrenamiento.js

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, Modal } from 'react-bootstrap';
import axios from 'axios';

const AdminEntrenamiento = () => {
  const [templates, setTemplates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEjercicioModal, setShowEjercicioModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [ejercicios, setEjercicios] = useState([]);
  const [templateEjercicios, setTemplateEjercicios] = useState([]);
  const [filteredEjercicios, setFilteredEjercicios] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [ejercicioFilter, setEjercicioFilter] = useState({ nombre: '', tipo: '' });
  const [templateFilter, setTemplateFilter] = useState({ nombre: '', tipo: '' });

  const [nuevoEjercicio, setNuevoEjercicio] = useState({
    nombre: '',
    descripcion: '',
    tipo: '',
    link: '',
    variable_sugerida: ''
  });
  const [showTemplateModal, setShowTemplateModal] = useState(false); // Estado para el modal de templates
  const [nuevoTemplate, setNuevoTemplate] = useState({ nombre: '', tipo: '' });
  const [planes, setPlanes] = useState([]);
  const [showModalPlan, setShowModalPlan] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [planDetalles, setPlanDetalles] = useState([]);
  const [nuevoPlan, setNuevoPlan] = useState({ nombre: '', descripcion: '', tipo: 'semanal' });
  const [editarEjercicio, setEditarEjercicio] = useState(null);

  useEffect(() => {
    fetchEjercicios();
    fetchTemplates();
    fetchPlanes();
  }, []);

  // Funciones para cargar datos
  const fetchEjercicios = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/entrenamiento/ejercicios`);
      setEjercicios(response.data);
      setFilteredEjercicios(response.data);
    } catch (error) {
      console.error('Error al obtener ejercicios:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/entrenamiento/templates`);
      setTemplates(response.data);
      setFilteredTemplates(response.data);
    } catch (error) {
      console.error('Error al obtener templates:', error);
    }
  };

  const fetchPlanes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/entrenamiento/planes`);
      setPlanes(response.data);
    } catch (error) {
      console.error('Error al obtener planes:', error);
    }
  };

  // Manejo de cambios en formularios
  const handleEjercicioChange = (e) => {
    setNuevoEjercicio({ ...nuevoEjercicio, [e.target.name]: e.target.value });
  };

  const handleTemplateChange = (e) => {
    setNuevoTemplate({ ...nuevoTemplate, [e.target.name]: e.target.value });
  };

  const handlePlanChange = (e) => {
    setNuevoPlan({ ...nuevoPlan, [e.target.name]: e.target.value });
  };

  // Funciones para guardar cada tipo de entidad
  const handleSaveEjercicio = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/entrenamiento/ejercicios`, nuevoEjercicio);
      setNuevoEjercicio({ nombre: '', descripcion: '', tipo: '', link: '', variable_sugerida: '' });
      fetchEjercicios();
      setShowEjercicioModal(false); // Cierra el modal después de guardar
    } catch (error) {
      console.error('Error al guardar el ejercicio:', error);
    }
  };

  // Guardar template
  const handleSaveTemplate = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/entrenamiento/templates`, nuevoTemplate);
      setNuevoTemplate({ nombre: '', tipo: '' });
      fetchTemplates(); // Actualizar la lista de templates
      setShowTemplateModal(false); // Cerrar el modal
    } catch (error) {
      console.error('Error al guardar el template:', error);
    }
  };

  const handleSavePlan = async () => {
    try {
      const payload = { ...nuevoPlan, detalles: planDetalles };
      if (currentPlan) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/entrenamiento/planes/${currentPlan.ID}`, payload);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/entrenamiento/planes`, payload);
      }
      setNuevoPlan({ nombre: '', descripcion: '', tipo: 'semanal' });
      setPlanDetalles([]);
      fetchPlanes();
      setShowModalPlan(false);
    } catch (error) {
      console.error('Error al guardar el plan:', error);
    }
  };

  const handleEditPlan = async (plan) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/entrenamiento/planes/${plan.ID}`);
      setCurrentPlan(plan);
      setNuevoPlan({
        nombre: plan.nombre,
        descripcion: plan.descripcion,
        tipo: plan.tipo,
      });
      setPlanDetalles(response.data.detalles || []);
      setShowModalPlan(true);
    } catch (error) {
      console.error('Error al cargar los detalles del plan:', error);
    }
  };

  const handleAddDetallePlan = () => {
    setPlanDetalles([...planDetalles, { TemplateEjercicioID: '', EjercicioID: '', series: '', repeticiones: '', peso_sugerido: '' }]);
  };

  const handleChangeDetallePlan = (index, field, value) => {
    const updatedDetalles = [...planDetalles];
    updatedDetalles[index][field] = value;
    setPlanDetalles(updatedDetalles);
  };

  const handleDeleteDetallePlan = (index) => {
    const updatedDetalles = planDetalles.filter((_, i) => i !== index);
    setPlanDetalles(updatedDetalles);
  };


  // Modal para asociar ejercicios a templates
  const openEjerciciosModal = async (template) => {
    setCurrentTemplate(template);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/entrenamiento/templates/${template.ID}/ejercicios`);
      console.log('ejercicios de template',response.data);
      setTemplateEjercicios(response.data.TemplateEjercicioDetalles || []);
      setShowModal(true);
    } catch (error) {
      console.error('Error al obtener ejercicios del template:', error);
    }
  };

  const handleAgregarEjercicio = () => {
    setTemplateEjercicios([
      ...templateEjercicios,
      { EjercicioID: '', series: '', repeticiones: '', peso_sugerido: '' }
    ]);
  };

  const handleChangeEjercicio = (index, field, value) => {
    const updatedEjercicios = [...templateEjercicios];
    updatedEjercicios[index][field] = value;
    setTemplateEjercicios(updatedEjercicios);
  };

  const removeEjercicioFromTemplate = (index) => {
    const updatedEjercicios = templateEjercicios.filter((_, i) => i !== index);
    setTemplateEjercicios(updatedEjercicios);
  };

  const saveEjerciciosToTemplate = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/entrenamiento/templates/${currentTemplate.ID}/ejercicios`, {
        ejercicios: templateEjercicios,
      });
      setShowModal(false);
      fetchTemplates();
    } catch (error) {
      console.error('Error al actualizar los ejercicios del template:', error);
    }
  };
  
  
  // Función para abrir el modal de edición
  const handleEditEjercicio = (ejercicio) => {
    setEditarEjercicio(ejercicio);
    setShowEjercicioModal(true); // Reutilizamos el modal de creación para la edición
  };

  // Función para guardar cambios en un ejercicio
  const handleSaveEditedEjercicio = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/entrenamiento/ejercicios/${editarEjercicio.ID}`, editarEjercicio);
      fetchEjercicios(); // Refrescar la lista
      setEditarEjercicio(null); // Limpiar el estado de edición
      setShowEjercicioModal(false); // Cerrar el modal
    } catch (error) {
      console.error('Error al editar el ejercicio:', error);
    }
  };

  // Función para eliminar un template
  const deleteTemplate = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/entrenamiento/templates/${id}`);
      fetchTemplates(); // Refrescar la lista
    } catch (error) {
      console.error('Error al eliminar el template:', error);
    }
  };


  const deleteEjercicio = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/entrenamiento/ejercicios/${id}`);
      fetchEjercicios();
    } catch (error) {
      console.error('Error al eliminar ejercicio:', error);
    }
  }

  // Filtrar ejercicios y templates
  const handleEjercicioFilterChange = (e) => {
    const { name, value } = e.target;
    setEjercicioFilter({ ...ejercicioFilter, [name]: value });
    setFilteredEjercicios(ejercicios.filter(ej => (
      (ej.nombre.toLowerCase().includes(ejercicioFilter.nombre.toLowerCase())) &&
      (ej.tipo.toLowerCase().includes(ejercicioFilter.tipo.toLowerCase()))
    )));
  };

  const handleTemplateFilterChange = (e) => {
    const { name, value } = e.target;
    setTemplateFilter({ ...templateFilter, [name]: value });
    setFilteredTemplates(templates.filter(t => (
      (t.nombre.toLowerCase().includes(templateFilter.nombre.toLowerCase())) &&
      (t.tipo.toLowerCase().includes(templateFilter.tipo.toLowerCase()))
    )));
  };

  return (
    <Container>
      <h1>Administración de Entrenamiento</h1>

      {/* Sección Ejercicios */}
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>
              Ejercicios
              <Button variant="primary" className="float-end" onClick={() => setShowEjercicioModal(true)}>Agregar Ejercicio</Button>
            </Card.Header>
            <Card.Body>
              <Form className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Buscar por nombre"
                  name="nombre"
                  value={ejercicioFilter.nombre}
                  onChange={(e) => handleEjercicioFilterChange(e)}
                />
                <Form.Control
                  type="text"
                  placeholder="Buscar por tipo"
                  name="tipo"
                  value={ejercicioFilter.tipo}
                  onChange={(e) => handleEjercicioFilterChange(e)}
                  className="mt-2"
                />
              </Form>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Tipo</th>
                    <th>Link</th>
                    <th>Variable Sugerida</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredEjercicios) && filteredEjercicios.map(ej => (
                    <tr key={ej.ID}>
                      <td>{ej.nombre}</td>
                      <td>{ej.descripcion}</td>
                      <td>{ej.tipo}</td>
                      <td><a href={ej.link} target="_blank" rel="noopener noreferrer">{ej.link ? 'Ver' : ''}</a></td>
                      <td>{ej.variable_sugerida}</td>
                      <td>
                        <Button variant="secondary" size="sm" onClick={() => handleEditEjercicio(ej)}>Editar</Button>{' '}
                        <Button variant="danger" size="sm" onClick={() => deleteEjercicio(ej.ID)}>Eliminar</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal para agregar o editar ejercicio */}
      <Modal show={showEjercicioModal} onHide={() => setShowEjercicioModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editarEjercicio ? 'Editar Ejercicio' : 'Agregar Nuevo Ejercicio'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEjercicioNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={editarEjercicio ? editarEjercicio.nombre : nuevoEjercicio.nombre}
                onChange={(e) =>
                  editarEjercicio
                    ? setEditarEjercicio({ ...editarEjercicio, nombre: e.target.value })
                    : handleEjercicioChange(e)
                }
              />
            </Form.Group>
            <Form.Group controlId="formEjercicioDescripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                name="descripcion"
                value={editarEjercicio ? editarEjercicio.descripcion : nuevoEjercicio.descripcion}
                onChange={(e) =>
                  editarEjercicio
                    ? setEditarEjercicio({ ...editarEjercicio, descripcion: e.target.value })
                    : handleEjercicioChange(e)
                }
              />
            </Form.Group>
            <Form.Group controlId="formEjercicioTipo">
              <Form.Label>Tipo</Form.Label>
              <Form.Control
                type="text"
                name="tipo"
                value={editarEjercicio ? editarEjercicio.tipo : nuevoEjercicio.tipo}
                onChange={(e) =>
                  editarEjercicio
                    ? setEditarEjercicio({ ...editarEjercicio, tipo: e.target.value })
                    : handleEjercicioChange(e)
                }
              />
            </Form.Group>
            <Form.Group controlId="formEjercicioLink">
              <Form.Label>Link</Form.Label>
              <Form.Control type="text" name="link" 
                value={editarEjercicio ? editarEjercicio.link : nuevoEjercicio.link}
                onChange={(e) =>
                  editarEjercicio
                    ? setEditarEjercicio({ ...editarEjercicio, link: e.target.value })
                    : handleEjercicioChange(e)
                }
              />
            </Form.Group>
            <Form.Group controlId="formEjercicioVariableSugerida">
              <Form.Label>Variable Sugerida</Form.Label>
              <Form.Control type="text" name="variable_sugerida" 
                value={editarEjercicio ? editarEjercicio.variable_sugerida : nuevoEjercicio.variable_sugerida} 
                onChange={(e) =>
                  editarEjercicio
                    ? setEditarEjercicio({ ...editarEjercicio, variable_sugerida: e.target.value })
                    : handleEjercicioChange(e)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEjercicioModal(false)}>Cerrar</Button>
          <Button
            variant="primary"
            onClick={editarEjercicio ? handleSaveEditedEjercicio : handleSaveEjercicio}
          >
            {editarEjercicio ? 'Guardar Cambios' : 'Guardar'}
          </Button>
        </Modal.Footer>
      </Modal>     

      {/* Sección Templates */}
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>
              Templates de Ejercicio 
              <Button variant="primary" className="float-end" onClick={() => setShowTemplateModal(true)}>
                Agregar Template
              </Button>
            </Card.Header>
            <Card.Body>
              <Form className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Buscar por nombre"
                  name="nombre"
                  value={templateFilter.nombre}
                  onChange={handleTemplateFilterChange}
                />
                <Form.Control
                  type="text"
                  placeholder="Buscar por tipo"
                  name="tipo"
                  value={templateFilter.tipo}
                  onChange={handleTemplateFilterChange}
                  className="mt-2"
                />
              </Form>
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Tipo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredTemplates) && filteredTemplates.map(t => (
                    <tr key={t.ID}>
                      <td>{t.nombre}</td>
                      <td>{t.tipo}</td>
                      <td>
                        <Button variant="secondary" onClick={() => openEjerciciosModal(t)}>Ver y Agregar Ejercicios</Button>{' '}
                        <Button variant="danger" onClick={() => deleteTemplate(t.ID)}>Eliminar</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      {/* Modal para agregar nuevo template */}
      <Modal show={showTemplateModal} onHide={() => setShowTemplateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Nuevo Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTemplateNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={nuevoTemplate.nombre}
                onChange={handleTemplateChange}
              />
            </Form.Group>
            <Form.Group controlId="formTemplateTipo" className="mt-3">
              <Form.Label>Tipo</Form.Label>
              <Form.Control
                type="text"
                name="tipo"
                value={nuevoTemplate.tipo}
                onChange={handleTemplateChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTemplateModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveTemplate}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para administrar ejercicios de template */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Ejercicios en {currentTemplate?.nombre}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Ejercicio</th>
                <th>Series</th>
                <th>Repeticiones</th>
                <th>Peso Sugerido</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(templateEjercicios) && templateEjercicios.map((ej, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      as="select"
                      value={ej.EjercicioID}
                      onChange={(e) => handleChangeEjercicio(index, 'EjercicioID', e.target.value)}
                    >
                      <option value="">Seleccionar Ejercicio</option>
                      {Array.isArray(ejercicios) && ejercicios.map((ejercicio) => (
                        <option key={ejercicio.ID} value={ejercicio.ID}>
                          {ejercicio.nombre}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={ej.series}
                      onChange={(e) => handleChangeEjercicio(index, 'series', e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={ej.repeticiones}
                      onChange={(e) => handleChangeEjercicio(index, 'repeticiones', e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={ej.peso_sugerido}
                      onChange={(e) => handleChangeEjercicio(index, 'peso_sugerido', e.target.value)}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => removeEjercicioFromTemplate(index)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button variant="secondary" onClick={handleAgregarEjercicio} className="mt-3">
            Agregar Ejercicio
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={saveEjerciciosToTemplate}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Sección Planes */}
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header>
              Planes de Entrenamiento
              <Button variant="primary" className="float-end" onClick={() => setShowModalPlan(true)}>Agregar Plan</Button>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Tipo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(planes) && planes.map(plan => (
                    <tr key={plan.ID}>
                      <td>{plan.nombre}</td>
                      <td>{plan.descripcion}</td>
                      <td>{plan.tipo}</td>
                      <td>
                        <Button variant="secondary" size="sm" onClick={() => handleEditPlan(plan)}>
                          Ver/Editar
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal Plan */}

      <Modal show={showModalPlan} onHide={() => setShowModalPlan(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{currentPlan ? 'Editar Plan' : 'Nuevo Plan'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="planNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={nuevoPlan.nombre}
                onChange={handlePlanChange}
              />
            </Form.Group>
            <Form.Group controlId="planDescripcion" className="mt-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                name="descripcion"
                value={nuevoPlan.descripcion}
                onChange={handlePlanChange}
              />
            </Form.Group>
            <Form.Group controlId="planTipo" className="mt-3">
              <Form.Label>Tipo</Form.Label>
              <Form.Control
                as="select"
                name="tipo"
                value={nuevoPlan.tipo}
                onChange={handlePlanChange}
              >
                <option value="semanal">Semanal</option>
                <option value="mensual">Mensual</option>
              </Form.Control>
            </Form.Group>

            <h5 className="mt-4">Detalles del Plan</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Template</th>
                  <th>Ejercicio</th>
                  <th>Series</th>
                  <th>Repeticiones</th>
                  <th>Peso</th>
                  <th>Día</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {planDetalles.map((detalle, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        as="select"
                        value={detalle.TemplateEjercicioID}
                        onChange={(e) =>
                          handleChangeDetallePlan(index, 'TemplateEjercicioID', e.target.value)
                        }
                      >
                        <option value="">Seleccionar</option>
                        {templates.map((template) => (
                          <option key={template.ID} value={template.ID}>
                            {template.nombre}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={detalle.EjercicioID}
                        onChange={(e) =>
                          handleChangeDetallePlan(index, 'EjercicioID', e.target.value)
                        }
                      >
                        <option value="">Seleccionar</option>
                        {ejercicios.map((ejercicio) => (
                          <option key={ejercicio.ID} value={ejercicio.ID}>
                            {ejercicio.nombre}
                          </option>
                        ))}
                      </Form.Control>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={detalle.series}
                        onChange={(e) =>
                          handleChangeDetallePlan(index, 'series', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={detalle.repeticiones}
                        onChange={(e) =>
                          handleChangeDetallePlan(index, 'repeticiones', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={detalle.peso_sugerido}
                        onChange={(e) =>
                          handleChangeDetallePlan(index, 'peso_sugerido', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Form.Control
                        as="select"
                        value={detalle.dia}
                        onChange={(e) => handleChangeDetallePlan(index, 'dia', e.target.value)}
                      >
                        <option value="">Día</option>
                        <option value="1">Lunes</option>
                        <option value="2">Martes</option>
                        <option value="3">Miércoles</option>
                        <option value="4">Jueves</option>
                        <option value="5">Viernes</option>
                        <option value="6">Sábado</option>
                        <option value="7">Domingo</option>
                      </Form.Control>
                    </td>
                    <td>
                      <Button variant="danger" size="sm" onClick={() => handleDeleteDetallePlan(index)}>
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button variant="secondary" onClick={handleAddDetallePlan}>
              Agregar Detalle
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalPlan(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleSavePlan}>
            Guardar Plan
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AdminEntrenamiento;
