import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card, Button, Form, Row, Col, Table } from 'react-bootstrap';
import { formatFecha } from '../utils/dateUtils'; // Asumimos que tienes esta función para formatear las fechas
import tiposSesion from '../utils/tipoSesion';
import tiposLanzamiento from '../utils/tipoLanzamiento';
import './ZonaBotonera.css';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SesionBullpenLanzamientos = () => {
  const { idSesionBullpen } = useParams(); // Obtener el ID de la sesión desde la URL
  const [sesion, setSesion] = useState(null); // Sesión actual
  const [lanzamientos, setLanzamientos] = useState([]); // Lista de lanzamientos
  const [nuevoLanzamiento, setNuevoLanzamiento] = useState({
    TipoLanzamiento: '',
    Intencion: '1',
    Resultado: '',
    Velocidad: ''
  });
  // const [resumen, setResumen] = useState({});
  const navigate = useNavigate(); // Para regresar a la lista de sesiones

  useEffect(() => {
    // Obtener la sesión y los lanzamientos
    const fetchSesion = async () => {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sesionbullpen/${idSesionBullpen}`, authHeader);
        setSesion(response.data);
        setLanzamientos(response.data.SesionBullpenLanzamientos || []); // Asegúrate de que lanzamientos sea un array
      } catch (error) {
        console.error('Error al obtener la sesión y los lanzamientos', error);
      }
    };
    fetchSesion();
  }, [idSesionBullpen]);

  /*
  useEffect(() => {
    const fetchResumen = async () => {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sesionbullpen/${idSesionBullpen}/resumen`, authHeader);
        setResumen(response.data);
      } catch (error) {
        console.error('Error al obtener el resumen de lanzamientos', error);
      }
    };
    fetchResumen();
  }, [idSesionBullpen]);
  */

  const handleAgregarLanzamiento = async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    try {
      if (!nuevoLanzamiento.TipoLanzamiento || !nuevoLanzamiento.Resultado) {
        alert('Por favor, completa todos los campos requeridos.');
        return;
      }

      // Si el valor de Velocidad está vacío, asignar 0 por defecto
      const lanzamientoConVelocidadDefecto = {
        ...nuevoLanzamiento,
        Velocidad: nuevoLanzamiento.Velocidad === '' ? 0 : nuevoLanzamiento.Velocidad,
      };

      console.log('Datos enviados:', lanzamientoConVelocidadDefecto);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/sesionbullpen/lanzamientos/${idSesionBullpen}`, 
        lanzamientoConVelocidadDefecto, 
        authHeader
      );
      
      setLanzamientos([...lanzamientos, response.data]);
      
      // Reiniciar el formulario, pero mantener el tipo de lanzamiento seleccionado
      setNuevoLanzamiento({
        TipoLanzamiento: nuevoLanzamiento.TipoLanzamiento, // No reiniciamos este campo
        Intencion: '1', // Intención por defecto a 1
        Resultado: '',
        Velocidad: nuevoLanzamiento.Velocidad
      });
    } catch (error) {
      console.error('Error al agregar el lanzamiento', error);
    }
  };

  const handleZonaClickIntencion = (zona) => {
    setNuevoLanzamiento({ ...nuevoLanzamiento, Intencion: zona });
  };

  const handleZonaClickResultado = (zona) => {
    setNuevoLanzamiento({ ...nuevoLanzamiento, Resultado: zona });
  };

  const handleEliminarLanzamiento = async (idLanzamiento) => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/sesionbullpen/lanzamientos/${idLanzamiento}`, authHeader);
      setLanzamientos(lanzamientos.filter(lanzamiento => lanzamiento.IDLanzamientos !== idLanzamiento));
    } catch (error) {
      console.error('Error al eliminar el lanzamiento', error);
    }
  };

  /*
  const calcularEstadisticasPorTipo = () => {
    const estadisticas = tiposLanzamiento.map((tipo) => {
      const lanzamientosPorTipo = lanzamientos.filter(l => l.TipoLanzamiento === tipo.value);
      const strikes = lanzamientosPorTipo.filter(l => l.Resultado >= 1 && l.Resultado <= 5);
      const velocidadTotal = lanzamientosPorTipo.reduce((total, l) => total + (l.Velocidad || 0), 0);
      const velocidadMaxima = Math.max(...lanzamientosPorTipo.map(l => l.Velocidad || 0));

      return {
        tipo: tipo.label,
        porcentajeStrikes: lanzamientosPorTipo.length ? (strikes.length / lanzamientosPorTipo.length) * 100 : 0,
        promedioVelocidad: lanzamientosPorTipo.length ? velocidadTotal / lanzamientosPorTipo.length : 0,
        velocidadMaxima,
      };
    });

    return estadisticas.filter(est => est.porcentajeStrikes || est.promedioVelocidad || est.velocidadMaxima);
  };
  */
  const calcularEstadisticasPorTipo = () => {
    return tiposLanzamiento
      .map((tipo) => {
        const lanzamientosPorTipo = lanzamientos.filter(l => l.TipoLanzamiento === tipo.value);
        if (lanzamientosPorTipo.length === 0) return null;

        const strikes = lanzamientosPorTipo.filter(l => l.Resultado >= 1 && l.Resultado <= 5);
        const velocidadTotal = lanzamientosPorTipo.reduce((total, l) => total + (l.Velocidad || 0), 0);
        const velocidadMaxima = Math.max(...lanzamientosPorTipo.map(l => l.Velocidad || 0));

        return {
          tipo: tipo.label,
          porcentajeStrikes: (strikes.length / lanzamientosPorTipo.length) * 100,
          promedioVelocidad: velocidadTotal / lanzamientosPorTipo.length,
          velocidadMaxima,
        };
      })
      .filter(est => est); // Filtra los tipos de lanzamientos sin registros
  };

  const estadisticas = calcularEstadisticasPorTipo();

  const strikePercentageData = {
    labels: estadisticas.map(est => est.tipo),
    datasets: [
      {
        label: 'Porcentaje de Strikes',
        data: estadisticas.map(est => est.porcentajeStrikes),
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const avgSpeedData = {
    labels: estadisticas.map(est => est.tipo),
    datasets: [
      {
        label: 'Promedio de Velocidad (MPH)',
        data: estadisticas.map(est => est.promedioVelocidad),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const maxSpeedData = {
    labels: estadisticas.map(est => est.tipo),
    datasets: [
      {
        label: 'Velocidad Máxima (MPH)',
        data: estadisticas.map(est => est.velocidadMaxima),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const maxSpeedOptions = {
    responsive: true,
    scales: {
      y: {
        min: 40,
        max: 100, // Fija el eje Y de 0 a 100%        
      },
    },    
  };

  const strikePercentageOptions = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 100, // Fija el eje Y de 0 a 100%
        ticks: {
          callback: function(value) {
            return value + '%'; // Agrega el símbolo de porcentaje en las etiquetas del eje Y
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            return `${context.raw.toFixed(2)}%`;
          },
        },
      },
    },
  };

  if (!sesion) {
    return <p>Cargando sesión de bullpen...</p>;
  }

  // Obtener el label del tipo de sesión
  const tipoSesionLabel = tiposSesion.find(tipo => tipo.value === sesion.Tipo)?.label || 'N/A';

  // Obtener el nombre y apellido del jugador
  const jugadorNombre = sesion?.Jugador?.nombre || 'N/A';
  const jugadorApellido = sesion?.Jugador?.apellido || 'N/A';

  return (
    <div className="container mt-4">
      {/* Cabecera */}
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
                <Card.Title>{jugadorNombre} {jugadorApellido}</Card.Title> {/* Nombre y apellido del jugador */}
                <Card.Subtitle>Sesión del {formatFecha(sesion.Fecha)} - {tipoSesionLabel}</Card.Subtitle> {/* Label del tipo de sesión */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Botón para regresar a la lista de sesiones */}
      <Row className="mb-3">
        <Col>
          <Button variant="secondary" onClick={() => navigate(-1)}>Volver a la lista de sesiones</Button>
        </Col>
      </Row>

      <h2>Resumen de la sesión</h2>
      {/* Gráficos de Resumen */}
      <Row>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Porcentaje de Strikes</Card.Title>
              <Bar data={strikePercentageData} options={strikePercentageOptions}/>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Promedio de Velocidad por Tipo</Card.Title>
              <Bar data={avgSpeedData} options={maxSpeedOptions}/>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Velocidad Máxima por Tipo</Card.Title>
              <Bar data={maxSpeedData} options={maxSpeedOptions}/>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Lista de lanzamientos */}
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header>Lanzamientos</Card.Header>
            <Card.Body>
              {lanzamientos && lanzamientos.length === 0 ? (
                <p>No hay lanzamientos registrados aún.</p>
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Tipo de Lanzamiento</th>
                      <th>Intención</th>
                      <th>Resultado</th>
                      <th>Velocidad (MPH)</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lanzamientos.map((lanzamiento, index) => (
                      <tr key={lanzamiento.IDLanzamientos}>
                        <td>{index + 1}</td>
                        <td>{tiposLanzamiento.find(t => t.value === parseInt(lanzamiento.TipoLanzamiento))?.label || 'N/A'}</td>
                        <td>{lanzamiento.Intencion || 'N/A'}</td>
                        <td>{lanzamiento.Resultado || 'N/A'}</td>
                        <td>{lanzamiento.Velocidad}</td>
                        <td>
                            <Button 
                                variant="danger" 
                                size="sm" 
                                onClick={() => handleEliminarLanzamiento(lanzamiento.IDLanzamientos)}>
                                &times; {/* Ícono de cruz */}
                            </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Agregar nuevo lanzamiento */}
      <Row>
        <Col>
          <Card>
            <Card.Header>Agregar nuevo lanzamiento</Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={3}>
                    <Form.Group controlId="formTipoLanzamiento">
                      <Form.Label>Tipo de Lanzamiento</Form.Label>
                      <Form.Control
                        as="select"
                        value={nuevoLanzamiento.TipoLanzamiento}
                        onChange={(e) => setNuevoLanzamiento({ ...nuevoLanzamiento, TipoLanzamiento: e.target.value })}
                      >
                        <option value="">Selecciona un tipo</option>
                        {tiposLanzamiento.map(tipo => (
                          <option key={tipo.value} value={tipo.value}>
                            {tipo.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="formIntencion">
                      <Form.Label>Intención</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={nuevoLanzamiento.Intencion}
                        onChange={(e) => setNuevoLanzamiento({ ...nuevoLanzamiento, Intencion: e.target.value })}
                      />
                    </Form.Group>
                    {/* Botonera de Zonas para la Intención */}
                    <div className="zona-container">
                      <div className="grid-layout">
                        {[...Array(17).keys()].map(i => (
                          <button type="button" className={`grid-item item-${i + 1}`} key={i} onClick={() => handleZonaClickIntencion(i + 1)}>{i + 1}</button>
                        ))}
                      </div>
                    </div>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="formResultado">
                      <Form.Label>Resultado</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={nuevoLanzamiento.Resultado}
                        onChange={(e) => setNuevoLanzamiento({ ...nuevoLanzamiento, Resultado: e.target.value })}
                      />
                    </Form.Group>
                    {/* Botonera de Zonas para el Resultado */}
                    <div className="zona-container">
                      <div className="grid-layout">
                        {[...Array(17).keys()].map(i => (
                          <button type="button" className={`grid-item item-${i + 1}`} key={i} onClick={() => handleZonaClickResultado(i + 1)}>{i + 1}</button>
                        ))}
                      </div>
                    </div>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="formVelocidad">
                      <Form.Label>Velocidad</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        value={nuevoLanzamiento.Velocidad}
                        onChange={(e) => setNuevoLanzamiento({ ...nuevoLanzamiento, Velocidad: e.target.value })}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault(); // Evita que el formulario se envíe por defecto
                            handleAgregarLanzamiento(); // Llama a la función para agregar el lanzamiento
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Button variant="primary" className="mt-3" onClick={handleAgregarLanzamiento}>
                  Agregar lanzamiento
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SesionBullpenLanzamientos;
