import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Image, Card, Button, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import banderas from '../utils/banderas';
import '../JugadorProfile.css'; // Usaremos CSS para ajustar estilos
import { FaInstagram } from 'react-icons/fa';
import moment from 'moment';

const formatFecha = (fecha) => {
  //const date = new Date(fecha);
  //return date.toLocaleDateString('es-AR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  return moment(fecha).format('DD/MM/YYYY');
};

const calcularEdad = (fechaNacimiento) => {
  const hoy = new Date();
  const fechaNac = new Date(fechaNacimiento);
  let edad = hoy.getFullYear() - fechaNac.getFullYear();
  const mes = hoy.getMonth() - fechaNac.getMonth();
  if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNac.getDate())) {
    edad--;
  }
  return edad;
};

const JugadorProfile = () => {
  const { id } = useParams(); // Obtener el ID del jugador desde la URL
  const [jugador, setJugador] = useState(null);
  const [bio, setBio] = useState('');
  const [apodo, setApodo] = useState('');
  const [posicion, setPosicion] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [telefono, setTelefono] = useState('');
  const [mail, setMail] = useState('');
  const [ig, setIg] = useState('');
  const [photo, setPhoto] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [user, setUser] = useState(null); // Estado para almacenar al usuario logueado
  const [mediciones, setMediciones] = useState({
    maxPulldown: null,
    maxBullpen: null,
    maxExit: null,
    ultimaMedicionPeso: null,
    ultimaMedicionAltura: null,
  });

  const [maxBullpen, setMaxBullpen] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ tipoMedicion: '', valor: '', fecha: '' });

  const [feedbacks, setFeedbacks] = useState([]); 
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackData, setFeedbackData] = useState({ Texto: '', Publico: 'N' });

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const fetchJugador = async () => {
      try {
        let response;
        let jugadorId;

        if (id) {
          // Si hay un ID en la URL, busca el perfil de ese jugador
          jugadorId = id;
          response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores/${id}`, authHeader);
        } else {
          // Si no hay ID, carga el perfil del usuario logueado
          response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
          jugadorId = response.data.Jugador.id;
          response = { data: response.data.Jugador }; // Acceder a la información del jugador dentro de la respuesta del usuario
        }
        setJugador(response.data);
        setBio(response.data.bio); // Inicializar bio
        setApodo(response.data.apodo || '');
        setPosicion(response.data.posicion || '');
        setCiudad(response.data.ciudad || '');
        setTelefono(response.data.telefono || '');
        setMail(response.data.mail || '');
        setIg(response.data.ig);
        // Ajuste de la URL de la foto
        const baseUrl = process.env.NODE_ENV === 'production' 
          ? '' // En producción, la URL de Google Cloud ya es completa
          : process.env.REACT_APP_API_URL; // En desarrollo, usaremos la base de la API local
        const defaultPhotoPath = '/default-profile.jpg';
        //console.log('url foto',`${baseUrl}${response.data.foto}`);
        setPhoto(response.data.foto ? `${baseUrl}${response.data.foto}` : `${baseUrl}${defaultPhotoPath}`);

        fetchMediciones(jugadorId);
        fetchMaxVelocidadBullpen(jugadorId);
        fetchUltimasMediciones(jugadorId);
        fetchFeedbacks(jugadorId);
      } catch (error) {
        console.error('Error al obtener el jugador:', error);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, authHeader);
        setUser(response.data); // Guardar los datos del usuario logueado
      } catch (error) {
        console.error('Error al obtener los datos del usuario:', error);
      }
    };

    const fetchMediciones = async (jugadorId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mediciones/maximas/${jugadorId}`, authHeader);
        setMediciones((prevMediciones) => ({
          ...prevMediciones,
          maxPulldown: response.data.maxPulldown,
          maxExit: response.data.maxExit,
        }));
      } catch (error) {
        console.error('Error al obtener las mediciones:', error);
      }
    };

    const fetchMaxVelocidadBullpen = async (jugadorId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sesionbullpen/jugador/${jugadorId}/max-velocidad-bullpen`, authHeader);
        setMaxBullpen(response.data); // Guardar la velocidad máxima
      } catch (error) {
        console.error('Error al obtener la velocidad máxima de bullpen:', error);
      }
    };

    const fetchUltimasMediciones = async (jugadorId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mediciones/ultimas/${jugadorId}`, authHeader);
        setMediciones((prevMediciones) => ({
          ...prevMediciones,
          ultimaMedicionPeso: response.data.ultimaMedicionPeso,
          ultimaMedicionAltura: response.data.ultimaMedicionAltura,
        }));
      } catch (error) {
        console.error('Error al obtener las últimas mediciones:', error);
      }
    };

    const fetchFeedbacks = async (jugadorId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/feedbacks/jugador/${jugadorId}`, authHeader);
        if (response.status === 200) {
          setFeedbacks(response.data);
        } else {
          setFeedbacks([]); // No hay feedbacks, establecer un array vacío
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log('No se encontraron feedbacks para este jugador.');
          setFeedbacks([]); // Inicializa como vacío si no hay feedbacks
        } else {
          console.error('Error al obtener feedbacks:', error);
        }
      }
    };

    fetchJugador();
    fetchUser(); // Obtener los datos del usuario logueado
    
  }, [id]);

  const handleSaveBio = async () => {
    try {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/api/jugadores/profile/update`, {
        bio,
        photo
      }, authHeader);
      setIsEditing(false);
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
    }
  };

  const handleSaveProfile = async () => {
    try {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      await axios.post(`${process.env.REACT_APP_API_URL}/api/jugadores/profile/update`, {
        bio,
        apodo,
        posicion,
        ciudad,
        telefono,
        mail,
        ig
      }, authHeader);
      setIsEditing(false);
      handleCloseEditProfileModal();
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
    }
  };

  const handleShowModal = (tipoMedicion) => {
    setModalData({ ...modalData, tipoMedicion });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData({ tipoMedicion: '', valor: '', fecha: '' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModalData({ ...modalData, [name]: value });
  };

  const handleSave = async () => {
    const { tipoMedicion, valor, fecha, link } = modalData;
    let tipoMedicionRef;
  
    // Definir el tipoMedicionRef dependiendo de lo que se quiera actualizar
    switch (tipoMedicion) {
      case 'peso':
        tipoMedicionRef = 4;
        break;
      case 'altura':
        tipoMedicionRef = 5;
        break;
      case 'pulldown':
        tipoMedicionRef = 1;
        break;
      case 'exit':
        tipoMedicionRef = 7;
        break;
      default:
        tipoMedicionRef = null;
    }
  
    if (!tipoMedicionRef || !valor || !fecha) {
      alert('Por favor, ingresa todos los valores.');
      return;
    }
  
    try {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
  
      const jugadorId = id || (jugador ? jugador.id : null); // Asegurar que siempre tenemos un jugador ID válido
  
      if (!jugadorId) {
        alert('No se puede determinar el ID del jugador. Inténtalo de nuevo.');
        return;
      }
  
      await axios.post(`${process.env.REACT_APP_API_URL}/api/mediciones/crear`, {
        IDJugador: jugadorId,
        TipoMedicion: tipoMedicionRef,
        Maximo: valor,
        Fecha: fecha,
        Link: link
      }, authHeader);

      // Actualizar la medición correspondiente en el estado
      setMediciones((prevMediciones) => ({
        ...prevMediciones,
        [tipoMedicion]: { Maximo: valor, Fecha: fecha },
      }));
  
      // Cerrar modal y actualizar datos después de guardar
      handleCloseModal();
      
    } catch (error) {
      console.error('Error al guardar la medición:', error);
    }
  };

  const handleShowFeedbackModal = () => {
    setShowFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false);
    setFeedbackData({ Texto: '', Publico: 'N' });
  };

  const handleFeedbackChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData({ ...feedbackData, [name]: value });
  };

  const handleEditProfile = () => {
    setShowEditProfileModal(true);
  };

  const handleCloseEditProfileModal = () => {
    setShowEditProfileModal(false);
  };

  const handleSaveFeedback = async () => {
    try {
      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      const { Texto, Publico } = feedbackData;
      const feedback = {
        JugadorID: jugador.id,
        CoachID: user.id,
        Fecha: new Date(),
        Publico: Publico ? 'Y' : 'N',
        Texto,
        SesionBullpenID: null,
        PartidoID: null,
      };
      
      await axios.post(`${process.env.REACT_APP_API_URL}/api/feedbacks/crear`, feedback, authHeader);
      
      handleCloseFeedbackModal();
      
      const feedbacksResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/feedbacks/jugador/${jugador.id}`, authHeader);
      setFeedbacks(feedbacksResponse.data);
    } catch (error) {
      console.error('Error al guardar feedback:', error);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadPhoto = async () => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    };

    if (!selectedFile) {
      alert('Por favor, selecciona una imagen para subir.');
      return;
    }

    const formData = new FormData();
    formData.append('photo', selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/jugadores/profile/upload-photo`,
        formData,
        authHeader
      );

      //setPhoto(response.data.imageUrl);
      setPhoto(response.data.foto); // Cambiamos `imageUrl` a `photo` para que coincida con el backend
      alert('Foto de perfil subida correctamente');
    } catch (error) {
      console.error('Error al subir la foto:', error);
      alert('Error al subir la foto de perfil');
    }
  };

  if (!jugador) {
    return <p>Cargando perfil del jugador...</p>;
  }

  // Verificar si el perfil que está visualizando es el propio
  const isOwnProfile = user && user.id === jugador.userId;

  const isCoachOrAdmin = user && (user.role === 'coach' || user.role === 'admin');

  // Verificar si el usuario está logueado y tiene el role disponible
  const userRole = user && user.role ? user.role : 'visit';

  // Filtrar feedbacks para el jugador logueado (solo mostrar públicos si es jugador)
  const filteredFeedbacks = feedbacks.filter(feedback => {
    if (isCoachOrAdmin) return true;
    return feedback.Publico === 'Y';
  });

  const formatMedicion = (medicion) => {
    return medicion ? `${medicion.Maximo} mph (${formatFecha(medicion.Fecha)})` : 'Sin datos';
  };

  return (
    <Container>
      <Row className="mt-5">
        {/* Foto de perfil */}
        <Col md={4}>
          <Image src={photo} roundedCircle fluid />
          {isEditing && (
            <>
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>Subir nueva foto</Form.Label>
                <Form.Control type="file" name="photo" onChange={handleFileChange} />
              </Form.Group>
              <Button variant="primary" className="mt-2" onClick={handleUploadPhoto}>
                Subir Foto
              </Button>
            </>
          )}
        </Col>

        {/* Información básica */}
        <Col md={8}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Card.Title>{jugador.nombre} {jugador.apodo ? `"${jugador.apodo}"` : ''} {jugador.apellido}</Card.Title>
                  <Card.Text>
                    <strong>Nacimiento:</strong> {formatFecha(jugador.nacimiento)} ({calcularEdad(jugador.nacimiento)} años) <br />
                    <img
                      src={banderas[jugador.nacionalidad].url} // Usamos la bandera correspondiente
                      alt="Bandera"
                      style={{ width: '30px', height: '20px' }}
                    />{" "}
                    {banderas[jugador.nacionalidad].nombre}, {jugador.ciudad}
                  </Card.Text>
                  
                </div>

                {/* Número de casaca en la dorsal */}
                <div className="dorsal-container">
                  <div className="dorsal-number">#{jugador.casaca}</div>
                </div>
              </div>
              
              <strong>Posicion:</strong> {jugador.posicion} <br />
              <strong>Lanza:</strong> {jugador.lanza} <strong>Batea:</strong> {jugador.batea} <br />
              <strong>Inicio en el club:</strong> {formatFecha(jugador.inicio)} <br />             
              
                <Button
                  variant="link"
                  href={`https://www.instagram.com/${jugador.ig}`}
                  target="_blank"
                  className="instagram-button"
                  style={{ color: '#C13584', textDecoration: 'none' }} // Color de Instagram y sin subrayado
                >
                  <FaInstagram style={{ marginRight: '5px' }} /> Seguir en Instagram
                </Button>
              <br />

              {/* Mostrar Teléfono y Email solo para coach y admin */}
              {(userRole === 'coach' || userRole === 'admin') && (
                <>
                  <strong>Teléfono:</strong> <a href={`https://wa.me/549${jugador.telefono}`}>{jugador.telefono}</a> <br />
                  <strong>Email:</strong> {jugador.mail} <br />
                </>
              )}
              <div>
              {isEditing ? (
                <>
                    <Form.Control as="textarea" value={bio} onChange={(e) => setBio(e.target.value)} />
                    <Button variant="primary" onClick={handleSaveBio} className="mt-2">Guardar</Button>
                </>
                ) : (
                <>
                    <strong>Bio:</strong> <span>{bio}</span><br />
                    {isOwnProfile && (
                    //<Button variant="secondary" onClick={() => setIsEditing(true)} className="mt-2">Editar Bio</Button>
                    <Button variant="secondary" onClick={handleEditProfile} className="mt-2">Editar Perfil</Button>
                    )}
                </>
                )}
              </div>
              {isCoachOrAdmin && (
              <div className="mt-3">
                <Link to={`/entrenamiento/${jugador.id}`}>
                  <Button variant="primary">Planificación de Entrenamiento</Button>
                </Link>
              </div>
            )}
              
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Sección de MAX Cards */}
      <Row className="mt-4">        
        <Col>
          <Card>
            <Card.Header style={{ backgroundColor: '#e8f4fd' }}>Mediciones {isCoachOrAdmin && (
                <Link to={`/mediciones/${jugador.id}`}>
                  <Button variant="primary">Mediciones Dashboard</Button>
                </Link>
            )}</Card.Header>
            <Card.Body>
              {/* Sección PESO ALTURA */}
              <Row className="mt-1">
                <Col md={2}>
                  <Card className="max-card">
                    <Card.Body>
                      <Card.Title>Peso</Card.Title>
                      <h5>{mediciones.ultimaMedicionPeso ? `${mediciones.ultimaMedicionPeso.Maximo} kg` : 'Sin datos'}</h5>
                      <small>{mediciones.ultimaMedicionPeso ? `${formatFecha(mediciones.ultimaMedicionPeso.Fecha)}` : 'Sin datos'}</small><br></br>
                      {(userRole === 'coach' || userRole === 'admin' || isOwnProfile) && (
                      <Button variant="primary" className="mt-0" onClick={() => handleShowModal('peso')}>Actualizar</Button>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card className="max-card">
                    <Card.Body>
                      <Card.Title>Altura</Card.Title>
                      <h5>{mediciones.ultimaMedicionAltura ? `${mediciones.ultimaMedicionAltura.Maximo} cm` : 'Sin datos'}</h5>
                      <small>{mediciones.ultimaMedicionAltura ? `${formatFecha(mediciones.ultimaMedicionAltura.Fecha)}` : 'Sin datos'}</small><br></br>
                      {(userRole === 'coach' || userRole === 'admin' || isOwnProfile) && (
                      <Button variant="primary" className="mt-0" onClick={() => handleShowModal('altura')}>Actualizar</Button>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              
                <Col md={2}>
                  <Card className="max-card">
                    <Card.Body>
                      <Card.Title>MAX Pulldown</Card.Title>
                      <p>{formatMedicion(mediciones.maxPulldown)}</p>
                      {(userRole === 'coach' || userRole === 'admin') && (
                      <Button variant="primary" className="mt-0" onClick={() => handleShowModal('pulldown')}>Actualizar</Button>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card className="max-card">
                    <Card.Body>
                      <Card.Title>MAX Bullpen</Card.Title>
                      <p>{maxBullpen ? `${maxBullpen.velocidadMaxima} mph (${formatFecha(maxBullpen.fecha)})` : 'Sin datos'}</p>
                      {(userRole === 'coach' || userRole === 'admin' || isOwnProfile) && (
                      <Link to={`/bullpen/jugador/${jugador.id}`}>
                        <Button variant="primary" className="mt-0">Ver Detalle</Button>
                      </Link>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card className="max-card">
                    <Card.Body>
                      <Card.Title>MAX Exit</Card.Title>
                      <p>{formatMedicion(mediciones.maxExit)}</p>
                      {(userRole === 'coach' || userRole === 'admin') && (
                        <Button variant="primary" className="mt-0" onClick={() => handleShowModal('exit')}>Actualizar</Button>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card className="max-card">
                    <Card.Body>
                      <Card.Title>BLAST</Card.Title>
                      <p>...</p>
                      {(userRole === 'coach' || userRole === 'admin' || isOwnProfile) && (
                      <Link to={`/blast/jugador/${jugador.id}`}>
                        <Button variant="primary" className="mt-0">Ver Detalle</Button>
                      </Link>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>        
      </Row>

      {/* Sección de estadísticas */}
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header style={{ backgroundColor: '#e8f4fd' }}>Estadísticas</Card.Header>
            <Card.Body>
              <p>Próximamente...</p>
              <Link to={`/estadisticas-jugador/${jugador.id}`}>
                <Button variant="primary" className="mt-0">Ver Detalle</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Header style={{ backgroundColor: '#e8f4fd' }}>Reportes</Card.Header>
            <Card.Body>
              {filteredFeedbacks.length > 0 ? (
                filteredFeedbacks.map(feedback => (
                  <div key={feedback.id}>
                    <p><strong>{formatFecha(feedback.Fecha)} - Publico: </strong>{feedback.Publico}    <strong>Coach:</strong> {feedback.Coach.nombre} {feedback.Coach.apellido}</p>
                    <p><strong></strong> {feedback.Texto}</p>
                    {feedback.SesionBullpenID && (
                      <Link to={`/bullpen/sesion/${feedback.SesionBullpenID}`}>Ver Sesión de Bullpen</Link>
                    )}
                    <hr />
                  </div>
                ))
              ) : (
                <p>No hay reportes para mostrar.</p>
              )}

              {isCoachOrAdmin && (
                <Button variant="primary" onClick={handleShowFeedbackModal}>
                  Agregar Reporte
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Modal para agregar feedback */}
      {isCoachOrAdmin && (
        <Modal show={showFeedbackModal} onHide={handleCloseFeedbackModal}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Reporte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Texto del Reporte</Form.Label>
              <Form.Control
                as="textarea"
                name="Texto"
                value={feedbackData.Texto}
                onChange={handleFeedbackChange}
                placeholder="Ingresa el comentario o feedback"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Check
                type="checkbox"
                name="Publico"
                label="Hacer público"
                checked={feedbackData.Publico}
                onChange={(e) => setFeedbackData({...feedbackData, Publico: e.target.checked})}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseFeedbackModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleSaveFeedback}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Modal para actualizar las mediciones */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar {modalData.tipoMedicion}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Valor</Form.Label>
            <Form.Control
              type="number"
              name="valor"
              value={modalData.valor}
              onChange={handleInputChange}
              placeholder="Ingresa el valor"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="date"
              name="fecha"
              value={modalData.fecha}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Link</Form.Label>
            <Form.Control 
              type="text" 
              name="link" 
              value={modalData.link} 
              onChange={handleInputChange} 
              placeholder="Ingresa link de video o archivo"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para editar perfil */}
      <Modal show={showEditProfileModal} onHide={handleCloseEditProfileModal}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Perfil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Bio</Form.Label>
            <Form.Control
              as="textarea"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Apodo</Form.Label>
            <Form.Control
              type="text"
              value={apodo}
              onChange={(e) => setApodo(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Posición</Form.Label>
            <Form.Control
              type="text"
              value={posicion}
              onChange={(e) => setPosicion(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Ciudad de Nacimiento</Form.Label>
            <Form.Control
              type="text"
              value={ciudad}
              onChange={(e) => setCiudad(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="text"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Link de Instagram</Form.Label>
            <Form.Control
              type="text"
              value={ig}
              onChange={(e) => setIg(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formFile" className="mt-3">
            <Form.Label>Subir nueva foto</Form.Label>
            <Form.Control type="file" name="photo" onChange={handleFileChange} />
          </Form.Group>
          <Button variant="primary" className="mt-2" onClick={handleUploadPhoto}>
            Subir Foto
          </Button>          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditProfileModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveProfile}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
};

export default JugadorProfile;
