import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const MatchJugadores = () => {
  const { torneoID, partidoID } = useParams();
  const [excelData, setExcelData] = useState([]);
  const [jugadoresDB, setJugadoresDB] = useState([]);
  const [matches, setMatches] = useState({});
  const [excluir, setExcluir] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    const authHeader = {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const fetchExcelData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/estadisticasBateo/excel-data?torneoID=${torneoID}&partidoID=${partidoID}`,
          authHeader
        );
        console.log('Datos recibidos del csv:', response.data);
        setExcelData(response.data);
      } catch (error) {
        console.error('Error fetching Excel data:', error);
      }
    };

    const fetchJugadoresDB = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jugadores`, authHeader);
        const sortedJugadores = response.data.sort((a, b) => {
          return a.apellido.localeCompare(b.apellido) || a.nombre.localeCompare(b.nombre);
        });
        setJugadoresDB(sortedJugadores);
      } catch (error) {
        console.error('Error fetching Jugadores:', error);
      }
    };

    fetchExcelData();
    fetchJugadoresDB();
  }, [partidoID, torneoID]);

  const handleJugadorSelect = (nombreExcel, jugadorID) => {
    setMatches((prevMatches) => ({
      ...prevMatches,
      [nombreExcel]: jugadorID,
    }));
  };

  const handleExcludeToggle = (index) => {
    setExcluir((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSubmit = async () => {
    try {
      const filteredMatches = Object.keys(matches).reduce((acc, key, index) => {
        // Solo incluir las filas que no están marcadas como excluidas
        if (!excluir[index]) {
          acc[key] = matches[key];
        }
        return acc;
      }, {});

      console.log('Datos enviados:', {
        matchData: filteredMatches,
        estadisticasData: excelData,
        torneoID,
        partidoID,
    });

      const token = localStorage.getItem('jwt');
      const authHeader = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      // Envía el mapeo de jugadores al servidor
      await axios.post(`${process.env.REACT_APP_API_URL}/api/estadisticasBateo/match-jugadores`, { matchData: filteredMatches }, authHeader);

      // Envía las estadísticas
      await axios.post(`${process.env.REACT_APP_API_URL}/api/estadisticasBateo/guardar-estadisticas`, {
        matchData: filteredMatches,
        estadisticasData: excelData,
        torneoID: torneoID,
        partidoID: partidoID,
      }, authHeader);

      alert('Estadísticas guardadas correctamente.');
    } catch (error) {
      console.error('Error al guardar las estadísticas:', error);
    }
  };

  return (
    <div>
      <h1>Emparejar Jugadores del Archivo</h1>
      <table>
        <thead>
          <tr>
            <th>Jugador (Archivo)</th>
            <th>Seleccionar Jugador (Base de Datos)</th>
            <th>Excluir</th>
          </tr>
        </thead>
        <tbody>
          {excelData.map((row, index) => (
            <tr key={index}>
              <td>{row.Name || 'Nombre no disponible'}</td> {/* Ajusta el nombre del campo */}
              <td>
                <select onChange={(e) => handleJugadorSelect(row.Name, e.target.value)}>
                  <option value="">Selecciona un jugador</option>
                  {jugadoresDB.map((jugador) => (
                    <option key={jugador.id} value={jugador.id}>
                      {jugador.apellido} {jugador.nombre}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={excluir[index] || false}
                  onChange={() => handleExcludeToggle(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleSubmit}>Guardar Emparejamientos</button>
    </div>
  );
};

export default MatchJugadores;
